var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body container"},[_c('Breadcrumb',[_c('template',{slot:"lastPage"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],2),_c('h1',{staticClass:"text-center mt-5 mb-3"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_c('span',[_vm._v(_vm._s(_vm.$t("bill.form.receivableName")))])]),_c('el-col',{attrs:{"span":21}},[_c('el-input',{attrs:{"value":_vm.$store.getters['bill/billTitle']},on:{"input":value => {
            _vm.$store.dispatch('bill/setBillTitle', value);
          }}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.currency")))]),_c('el-col',{attrs:{"span":6}},[_c('CurrencySelector',{attrs:{"value":_vm.$store.getters['bill/billCurrency']},on:{"change":_vm.changeBillCurrency}})],1)],1),(_vm.isEditReceivable)?_c('el-alert',{attrs:{"show-icon":"","type":"info","closable":false}},[_vm._v(" 如果要修改家長或是報名表，請建立一筆新的未來款項。 ")]):_vm._e(),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.parent")))]),(!_vm.$store.getters['bill/billParent'].name)?_c('el-col',{attrs:{"span":6}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.$store.getters['bill/billParent'].id,"placeholder":_vm.$t('bill.form.searchParent'),"clearable":"","filterable":"","remote":"","remote-method":_vm.fetchParents},on:{"change":_vm.selectedParent}},_vm._l((_vm.searchStudents),function(student){return _c('el-option',{key:`${student.parent_user_id}${student.student_user_id}`,attrs:{"label":`${_vm.userMethods.displayName(
              student.parent_user_first_name,
              student.parent_user_last_name
            )}/${_vm.userMethods.displayName(
              student.student_user_first_name,
              student.student_user_last_name
            )}`,"value":student}})}),1)],1):_c('el-col',{attrs:{"span":6}},[_c('router-link',{attrs:{"to":{
          name: 'ProfileForAdmin',
          params: { id: _vm.$store.getters['bill/billParent'].id }
        }}},[_vm._v(" "+_vm._s(_vm.$store.getters["bill/billParent"].name)+" ")]),(!_vm.isEditReceivable)?_c('span',{on:{"click":_vm.removeParent}},[_c('i',{staticClass:"fas fa-edit action-icon"})]):_vm._e()],1)],1),(_vm.$store.getters['bill/billParent'].name)?_c('div',[(
        _vm.isEditReceivable && _vm.$store.getters['bill/billReceivableEnrollmentId']
      )?_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.unpaidEnrollment")))]),_c('el-col',{attrs:{"span":21}},[_c('router-link',{attrs:{"to":{
            name: 'EnrollmentDetail',
            params: { id: _vm.$store.getters['bill/billReceivableEnrollmentId'] }
          }}},[_vm._v(" "+_vm._s(_vm.$store.getters["bill/billReceivableEnrollmentTitle"][_vm.$i18n.locale])+" ")])],1)],1):(!_vm.isEditReceivable)?_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.unpaidEnrollment")))]),_c('el-col',{attrs:{"span":21}},[_c('el-select',{staticStyle:{"width":"80%"},attrs:{"value":_vm.$store.getters['bill/billReceivableEnrollmentId'],"clearable":""},on:{"change":value =>
              _vm.$store.commit('bill/setBillReceivableEnrollmentId', value)}},_vm._l((_vm.$store.getters[
              'bill/parentUnpaidEnrollments'
            ]),function(parentUnpaidEnrollment){return _c('el-option',{key:parentUnpaidEnrollment.id,attrs:{"label":parentUnpaidEnrollment.course_session.title.tw,"value":parentUnpaidEnrollment.id}})}),1),(
            _vm.$store.getters['bill/billReceivableEnrollmentId'] &&
              _vm.$store.getters['bill/billReceivableEnrollmentId'] > 0
          )?_c('div',{staticStyle:{"display":"inline-block","padding-left":"20px","width":"20%"}},[_c('el-popover',{attrs:{"placement":"top","width":"160"},model:{value:(_vm.markSessionToFreeVisible),callback:function ($$v) {_vm.markSessionToFreeVisible=$$v},expression:"markSessionToFreeVisible"}},[_c('p',[_vm._v("是否設置成免費課程?")]),_c('div',{staticStyle:{"text-align":"right","margin":"0"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){_vm.markSessionToFreeVisible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.markSessionToFree(
                    _vm.$store.getters['bill/billReceivableEnrollmentId']
                  )}}},[_vm._v("确定")])],1),_c('el-button',{attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("設置成免費課程")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('hr',{staticClass:"separate-line"}),_c('el-row',{staticClass:"rowsGap"},[_c('OrderItems',{attrs:{"editor":true,"isReceivableMode":true,"students":_vm.studentsByParent,"orderItems":_vm.$store.getters['bill/orderItems']},on:{"setBillTotalPrice":billTotalPrice => {
            _vm.$store.dispatch('bill/setBillTotalPrice', billTotalPrice);
          },"addOtherItem":_vm.addOtherItem,"setOtherItem":_vm.setOtherItem,"removeOtherOrderItem":_vm.removeOtherOrderItem}})],1),_c('el-row',[_c('el-col',{attrs:{"span":24,"align":"center"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmitReceivable}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }