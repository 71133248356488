<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ pageTitle }}
      </template>
    </Breadcrumb>
    <h1 class="text-center mt-5 mb-3">
      {{ pageTitle }}
    </h1>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">
        <span>{{ $t("bill.form.receivableName") }}</span>
      </el-col>
      <el-col :span="21">
        <el-input
          :value="$store.getters['bill/billTitle']"
          @input="
            value => {
              $store.dispatch('bill/setBillTitle', value);
            }
          "
        />
      </el-col>
    </el-row>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">{{ $t("bill.form.currency") }}</el-col>
      <el-col :span="6">
        <CurrencySelector
          :value="$store.getters['bill/billCurrency']"
          @change="changeBillCurrency"
        />
      </el-col>
    </el-row>
    <el-alert v-if="isEditReceivable" show-icon type="info" :closable="false">
      如果要修改家長或是報名表，請建立一筆新的未來款項。
    </el-alert>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">{{ $t("bill.form.parent") }}</el-col>
      <el-col :span="6" v-if="!$store.getters['bill/billParent'].name">
        <el-select
          style="width: 100%"
          :value="$store.getters['bill/billParent'].id"
          @change="selectedParent"
          :placeholder="$t('bill.form.searchParent')"
          clearable
          filterable
          remote
          :remote-method="fetchParents"
        >
          <el-option
            v-for="student in searchStudents"
            :key="`${student.parent_user_id}${student.student_user_id}`"
            :label="
              `${userMethods.displayName(
                student.parent_user_first_name,
                student.parent_user_last_name
              )}/${userMethods.displayName(
                student.student_user_first_name,
                student.student_user_last_name
              )}`
            "
            :value="student"
          />
        </el-select>
      </el-col>
      <el-col v-else :span="6">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: $store.getters['bill/billParent'].id }
          }"
        >
          {{ $store.getters["bill/billParent"].name }}
        </router-link>
        <span v-if="!isEditReceivable" @click="removeParent">
          <i class="fas fa-edit action-icon" />
        </span>
      </el-col>
    </el-row>
    <div v-if="$store.getters['bill/billParent'].name">
      <el-row
        v-if="
          isEditReceivable && $store.getters['bill/billReceivableEnrollmentId']
        "
        class="rowsGap"
        :gutter="20"
      >
        <el-col :span="3">{{ $t("bill.form.unpaidEnrollment") }}</el-col>
        <el-col :span="21">
          <router-link
            :to="{
              name: 'EnrollmentDetail',
              params: { id: $store.getters['bill/billReceivableEnrollmentId'] }
            }"
          >
            {{
              $store.getters["bill/billReceivableEnrollmentTitle"][$i18n.locale]
            }}
          </router-link>
        </el-col>
      </el-row>
      <el-row v-else-if="!isEditReceivable" class="rowsGap" :gutter="20">
        <el-col :span="3">{{ $t("bill.form.unpaidEnrollment") }}</el-col>
        <el-col :span="21">
          <el-select
            style="width: 80%"
            :value="$store.getters['bill/billReceivableEnrollmentId']"
            @change="
              value =>
                $store.commit('bill/setBillReceivableEnrollmentId', value)
            "
            clearable
          >
            <el-option
              v-for="parentUnpaidEnrollment in $store.getters[
                'bill/parentUnpaidEnrollments'
              ]"
              :key="parentUnpaidEnrollment.id"
              :label="parentUnpaidEnrollment.course_session.title.tw"
              :value="parentUnpaidEnrollment.id"
            />
          </el-select>
          <div
            v-if="
              $store.getters['bill/billReceivableEnrollmentId'] &&
                $store.getters['bill/billReceivableEnrollmentId'] > 0
            "
            style="display:inline-block;padding-left:20px;width: 20%"
          >
            <el-popover
              placement="top"
              width="160"
              v-model="markSessionToFreeVisible"
            >
              <p>是否設置成免費課程?</p>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="markSessionToFreeVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="
                    markSessionToFree(
                      $store.getters['bill/billReceivableEnrollmentId']
                    )
                  "
                  >确定</el-button
                >
              </div>
              <el-button slot="reference" type="primary"
                >設置成免費課程</el-button
              >
            </el-popover>
          </div>
        </el-col>
      </el-row>
      <hr class="separate-line" />
      <el-row class="rowsGap">
        <OrderItems
          :editor="true"
          :isReceivableMode="true"
          :students="studentsByParent"
          :orderItems="$store.getters['bill/orderItems']"
          @setBillTotalPrice="
            billTotalPrice => {
              $store.dispatch('bill/setBillTotalPrice', billTotalPrice);
            }
          "
          @addOtherItem="addOtherItem"
          @setOtherItem="setOtherItem"
          @removeOtherOrderItem="removeOtherOrderItem"
        />
      </el-row>
      <el-row>
        <el-col :span="24" align="center">
          <el-button type="primary" @click="handleSubmitReceivable">
            {{ $t("button.save") }}
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import moneyMixin from "@/mixins/money";
import userApi from "@/apis/user";
import bill from "@/apis/bill";
import Breadcrumb from "@/components/Breadcrumb";
import { CurrencySelector } from "@/components/selector";
import OrderItems from "@/components/bill/OrderItems.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    CurrencySelector,
    OrderItems
  },
  data() {
    return {
      markSessionToFreeVisible: false,
      searchStudents: [],
      studentsByParent: []
    };
  },
  computed: {
    pageTitle() {
      if (this.isEditReceivable) {
        return this.$t("pageTitle.editReceivable");
      }
      return this.$t("pageTitle.createReceivable");
    },
    isEditReceivable() {
      return this.$route.name === "editReceivable";
    },
    userMethods() {
      return user;
    }
  },
  mixins: [moneyMixin],
  async created() {
    await this.initialExchangeRate();
    await this.$store.dispatch("bill/initializeBill");
    if (this.isEditReceivable) {
      await this.$store.dispatch(
        "bill/getReceivable",
        this.$route.params.receivableId
      );
      this.selectedParent(
        {
          parent_user_id: this.$store.getters["bill/billParent"].id
        },
        true
      );
    }

    if (this.$route.name === "createReceivableWithEnrollment") {
      const parentId = await this.$store.dispatch(
        "bill/generateReceivableFromEnroll",
        Number(this.$route.params.id)
      );
      this.selectedParent({ parent_user_id: parentId }, true);
    }

    this.changeBillCurrency({
      id: this.$store.getters["bill/billParent"].defaultCurrency
    });
  },
  methods: {
    async markSessionToFree(id) {
      if(id){
        await bill.markSessionToFree(Number(id));
        this.$message({
          message: "已經設置為免費課程！",
          type: "success"
        });
        this.$router.go(-1);
      }
    },
    async fetchParents(searchText) {
      let queryParams = {
        keyword: searchText
      };

      const { students } = await userApi.searchParents(queryParams);
      this.searchStudents = students;
    },
    changeBillCurrency(currency) {
      this.$store.dispatch("bill/setBillCurrency", currency.id);
      this.updatePriceByCurrencyOfOrderItems(
        this.$store.getters["bill/orderItems"]
      );
    },
    async removeParent() {
      try {
        await this.$confirm(
          this.$t("bill.removeParentAlarm"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }
      this.$store.dispatch("bill/initializeBill");
    },
    async selectedParent(parent, isCreateByOtherMethod) {
      if (!parent.parent_user_id) return;
      if (!isCreateByOtherMethod) {
        this.$store.dispatch("bill/setBillParent", parent.parent_user_id);
      }
      this.$store.dispatch(
        "bill/getParentUnpaidEnrollments",
        parent.parent_user_id
      );
      const { users } = await userApi.getStudentByParentId(
        parent.parent_user_id
      );
      this.studentsByParent = users.map(user => ({
        ...user,
        user_id: user.id,
        name: this.userMethods.displayName(user.first_name, user.last_name)
      }));
    },
    updatePriceByCurrencyOfOrderItems(
      orderItems = this.$store.getters["bill/orderItems"]
    ) {
      const updatePriceByCurrencyOrderItems = orderItems.map(orderItem => {
        if (orderItem.type === "custom") {
          const billCurrency = this.$store.getters["bill/billCurrency"];
          return {
            ...orderItem,
            priceCurrency: billCurrency,
            priceByCurrency: orderItem.price
          };
        }

        return {
          ...orderItem,
          priceByCurrency: this.convertPriceByCurrency(
            orderItem.price,
            orderItem.priceCurrency,
            this.$store.getters["bill/billCurrency"]
          )
        };
      });
      this.$store.dispatch(
        "bill/setOrderItems",
        updatePriceByCurrencyOrderItems
      );
    },
    async addOtherItem(orderItem) {
      this.$store.dispatch("bill/addOtherItem", orderItem);
    },
    async setOtherItem(orderIndex, value) {
      const orderItems = this.$store.getters["bill/orderItems"];
      const newOrderItems = orderItems.map(orderItem => ({ ...orderItem }));
      newOrderItems[orderIndex] = {
        ...newOrderItems[orderIndex],
        ...value
      };
      this.$store.dispatch("bill/setOrderItems", newOrderItems);
      this.updatePriceByCurrencyOfOrderItems(
        this.$store.getters["bill/orderItems"]
      );
    },
    async removeOtherOrderItem(orderIndex) {
      const orderItems = this.$store.getters["bill/orderItems"];
      const newOrderItems = orderItems.filter(
        (orderItem, index) => index !== orderIndex
      );
      this.$store.dispatch("bill/setOrderItems", newOrderItems);
      this.updatePriceByCurrencyOfOrderItems(
        this.$store.getters["bill/orderItems"]
      );
    },
    studentIsExist(studentId) {
      const target = this.$store.getters["bill/tempStudents"].find(
        student => student.user_id === studentId && !student.edit
      );
      if (target) {
        this.$message.warning(this.$t("bill.message.theStudentAlreadyChooice"));
      }
      return Boolean(target);
    },
    async handleSubmitReceivable() {
      try {
        if (this.isEditReceivable) {
          await this.$store.dispatch(
            "bill/updateReceivable",
            this.$route.params.receivableId
          );
          this.$router.push({
            name: "ReceivableView",
            params: { receivableId: this.$route.params.receivableId }
          });
          this.$message.success(this.$t("message.update_success"));
        } else {
          await this.$store.dispatch("bill/createReceivable");
          this.$message.success(this.$t("message.create_success"));
          this.$router.push({ name: "Receivable" });
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }
};
</script>

<style scoped>
.rowsGap {
  margin: 20px 0px;
}

.closeSelectPaymentMethodBtns {
  margin: 20px 0px;
}

.paymentInfo {
  margin: 4px;
  padding: 9.5px;
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
